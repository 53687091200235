import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeaderOneColumn from "../assets/elements/StandardHeaderOneColumn";
import Cta from "../partials/Cta";
import SEO from "../components/seo";

import HistologyVideoDemo from "../../static/media/histology_annotation_video.webm";
import LogoCloud from "../assets/elements/LogoCloud";
import { StaticImage } from "gatsby-plugin-image";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import { Script } from "gatsby";
import { EncordGradientText } from "../utilities/DesignUtilities";
import CaseStudyStanford from "../partials/CaseStudyStanford";
import CustomLink from "../components/CustomLink";
import VideoComponent from "../components/VideoComponent";

export default function Histology({ location }) {
  return (
    <Layout location={location}>
      <LeftColours />
      <RightColours />

      <StandardHeaderOneColumn
        title={
          <>
            Accelerate <EncordGradientText text={"Histology AI"} /> Model
            Development With Encord
          </>
        }
        location={location}
        pageName="Histology AI Page"
        description={
          "Increase micro and macroscopic data annotation speeds, create datasets with rich labeling protocols and deliver better training data to your histology and pathology AI models."
        }
        heroGraphic={
          <>
            <VideoComponent loop autoPlay muted className="rounded-lg">
              <source
                src={HistologyVideoDemo}
                type="video/webm"
                width="768"
                height="432"
              />
            </VideoComponent>
          </>
        }
      />

      <LogoCloud
        title={"Our innovative and industry-leading healthcare customers"}
        pb={10}
        pbMd={10}
        numImages={5}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/1716e248-7485-43e9-9454-bb17670cd7d4_stanford-medicine-logo-vector.png?auto=compress,format"
            }
            alt={"Stanford Medicine Logo"}
            className={"h-24 w-32"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/8ed83228-e834-494f-84d4-06f0b698c5d6_logo_uhn.svg"
            }
            alt={"UHN logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageThree={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/ab584df2-0365-496d-a450-9df08c58fade_Memorial+Sloan+Kettering+Cancer+Center-1.svg"
            }
            alt={"MSK logo"}
            className={"h-20 w-44"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFour={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/aa834409-1594-49f1-872e-d6b0ec856626_NHS_gstt.svg"
            }
            alt={"St Thomas logo"}
            className={"h-24 w-36 lg:w-40"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFive={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/f3a7d750-8fcf-4a89-9212-59a427503a98_klc.svg"
            }
            alt={"KCL logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
      />
      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"How it works"}
        header={
          "Create Better Pathology and Histology Machine Learning Models With Encord"
        }
        description={
          <>
            <p>
              Encord allows you to annotate digital slides to create datasets
              for histology AI models, supervise the output and quality of your
              annotation teams and manage the expert review process.
            </p>
            <br />
            <p>
              Encord offers full support for all staining protocols (including
              hematoxylin and eosin stain (H&E), KI67 and HER2), keypoint and
              polygon annotation, a full label audit trail and the ability to
              create highly complex labeling protocols.
            </p>
          </>
        }
        features={[]}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../../static/media/histology_cell2.webp"}
            width={450}
            alt="Histology pathology annotation"
            className="rounded-lg"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/histology_cell1.webp"}
            width={450}
            alt="Example of labeling cells in histology"
            className="rounded-lg"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/histology_ontology.webp"}
            width={450}
            alt="Segmentation of brain in CT scan"
            className="rounded-lg"
          />
        }
        headerOne={"Efficient Histology Image Labeling"}
        headerTwo={"A Single Tool for Viewing and Labeling Digital Slides"}
        headerThree={"Powerful Labeling Protocols for Histology and Pathology"}
        descriptionOne={
          <>
            <p>
              Encord is designed to help your skilled histology annotators
              (whether PhD students, in-house or a hired workforce) improve
              their labeling efficiency.
            </p>
            <br />
            <p>
              Encord makes it quicker and easier to count cells with keypoint
              annotation and use this to create microscopy training data. And
              Encord’s polygon labeling tool allows your annotators to create
              macroscopic regional annotations for creating training data for a
              range of use cases, such as oncology diagnosis.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord gives you a single platform for viewing and annotating your
              digital slides. It reduces the need to book out expensive
              microscopy workstation time, as you can upload your digital slides
              and review for research from anywhere, anytime, directly in the
              Encord platform.
            </p>
            <br />
            <p>
              And then when you’re ready to start the annotation process, your
              digital slides are already in the Encord platform, saving time and
              creating a more efficient labeling workflow.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Encord supports rich labeling protocols within its microscopy and
              macroscopy image annotation tool.
            </p>
            <br />
            <p>
              You can build complex labeling protocols for as many feature types
              as you need to deliver the right histology training data to your
              machine learning teams.
            </p>
          </>
        }
      />

      <section className="pt-12 pb-12 border-t border-gray-200 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl px-4 mx-auto sm:px-6">
          <CaseStudyStanford includeCTALink />
        </div>
      </section>

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeTopBorder
        includeTopPadding
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../../static/media/histology_queue.webp"}
            width={600}
            alt="Expert review quality control for histology and pathology"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/histology_accuracy.webp"}
            width={550}
            alt="Encord improves the quality of your histology training data"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/histology_approval.webp"}
            width={250}
            alt="Encord supports FDA clearance and CE marking processes"
          />
        }
        headerOne={"More Efficient Clinical Data Operations"}
        headerTwo={"Deliver Better Histology Training Data"}
        headerThree={"Supporting Your Regulatory Journey"}
        descriptionOne={
          <>
            <p>
              Make your annotator management workflows more efficient with
              role-based access control, worklist support and configurable
              expert review pipelines.
            </p>
            <br />
            <p>
              Ensure your expert pathologists and histologists’ time is spent
              only looking at those images and labels that need their input.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord supports clinical operations teams to identify dataset
              issues, biases and imbalances (and even monitor the performance of
              individual annotators).
            </p>
            <br />
            <p>
              Clinical operations can provide better training data to machine
              learning teams and reduce the time it takes to create experiments
              for histology and pathology datasets.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Encord has been designed to make it easier for you to meet your
              regulatory submissions, including FDA and CE approval.
            </p>
            <br />
            <p>
              As well as being{" "}
              <CustomLink
                className="font-medium text-gray-600 hover:text-gray-900"
                to={"/security/"}
              >
                HIPAA and SOC2
              </CustomLink>{" "}
              compliant, Encord’s data pipelines, fully auditable labels and
              quality control and assurance features make regulatory compliance
              much less of a headache.
            </p>
          </>
        }
      />

      <Cta
        location={location}
        pageName={"Histology Page"}
        ctaText={"Get started today"}
      />
    </Layout>
  );
}

export const Head = () => (
  <>
    <Script id="g2crowd-tracking-script" defer>
      {`(function (c, p, d, u, id, i) {
  id = ''; // Optional Custom ID for user in your system
  u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
  i = document.createElement('script');
  i.type = 'application/javascript';
  i.async = true;
  i.src = u;
  d.getElementsByTagName('head')[0].appendChild(i);
}("1008306", document.location.href, document));`}
    </Script>
    <SEO
      title="Encord | Histology annotation and data platform"
      description="Create better pathology and histology machine learning training Data, improve annotator efficiency and accelerate model development "
    >
      <meta name="robots" content="noindex, nofollow" />
    </SEO>
  </>
);

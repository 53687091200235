import React from "react";

// logos should be done in 287x391

export default function LogoCloud({
  title,
  pb,
  pbMd,
  numImages,
  imageOne,
  imageTwo,
  imageThree,
  imageFour,
  imageFive,
  imageSix,
}) {
  return (
    <section className="relative">
      <div
        className={`relative max-w-6xl mx-auto px-4 sm:px-6 pb-${pb} md:pb-${pbMd}`}
      >
        <p className="text-center text-base font-semibold text-gray-500">
          {title}
        </p>

        <div
          className={`mt-6 grid grid-cols-2 items-center gap-8 md:grid-cols-${numImages} lg:grid-cols-${numImages}`}
        >
          {imageOne && (
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              {imageOne}
            </div>
          )}

          {imageTwo && (
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              {imageTwo}
            </div>
          )}

          {imageThree && (
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              {imageThree}
            </div>
          )}

          {imageFour && (
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              {imageFour}
            </div>
          )}

          {imageFive && (
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              {imageFive}
            </div>
          )}

          {imageSix && (
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              {imageSix}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

import React from "react";
import CustomLink from "../components/CustomLink";

const CaseStudyStanford = ({ includeCTALink }) => {
  return (
    <section>
      {/* Stanford use case */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div>
          <div className="relative inline-flex flex-col">
            <img
              loading="lazy"
              src="https://encord.cdn.prismic.io/encord/916cdeee-986d-412a-9d9e-2c6bd33b870d_image-page-standford-medicine-icon.svg"
              width={200}
              alt="Stanford Medicine"
            />
          </div>
        </div>
        <div className="pt-4">
          <div className="h4">Stanford Medicine</div>
          <p className="text-xl text-gray-600">
            The Division of Nephrology reduced experiment duration by 80% while
            processing 3x more images.
          </p>
        </div>
      </div>

      {/* Results */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-4 md:pb-4 md:pt-4">
          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:gap-16 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-5xl pt-8 md:pt-4">
            {/* 1st item */}
            <div className="text-left">
              <div className="h4 mb-1">Problem</div>
              <div className="text-gray-600">
                Stanford was using three different pieces of software to
                identify, annotate, and count podocytes and glomeruli in
                microscopy images.
              </div>
            </div>

            {/* 2nd item */}
            <div className="text-left">
              <div className="h4 mb-1">Solution</div>
              <div className="text-gray-600">
                Stanford started using Encord's annotation tools & SDK to
                automate segmentations, count, and calculate sizes of segments.
              </div>
            </div>

            {/* 3rd item */}
            <div className="text-left">
              <div className="h4 mb-1">Results</div>
              <div className="text-gray-600">
                With Encord, Stanford researchers reduced experiment duration
                from an average of 21 to 4 days while processing 3x the number
                of images.
              </div>
            </div>

            {/* 1st item */}
            <div className="text-center bg-white shadow-lg border-gray-200">
              <div className="h4 mb-1 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-400">
                80%
              </div>
              <div className="text-gray-600 py-4">
                Reduction in experiment duration
              </div>
            </div>

            {/* 2nd item */}
            <div className="text-center bg-white shadow-lg border-gray-200">
              <div className="h4 mb-1 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-400">
                3X
              </div>
              <div className="text-gray-600 py-4">Number of images</div>
            </div>

            {/* 3rd item */}
            <div className="text-center bg-white shadow-lg border-gray-200">
              <div className="h4 mb-1 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-400">
                1 platform
              </div>
              <div className="text-gray-600 py-4">... and not 3</div>
            </div>
          </div>
        </div>

        {includeCTALink ? (
          <div className="flex justify-end items-end">
            <CustomLink
              to="/try-it-free/"
              className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
            >
              <span>
                Get Free Trial To Learn How Stanford Medicine Got to Production
                AI Faster
              </span>
              <svg
                className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                viewBox="0 0 12 12"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </svg>
            </CustomLink>
          </div>
        ) : (
          <div className="flex justify-end items-end">
            <a
              href="https://www.biorxiv.org/content/10.1101/2021.10.14.464296v1.full"
              className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Learn more</span>
              <svg
                className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                viewBox="0 0 12 12"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </svg>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default CaseStudyStanford;

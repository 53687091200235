import React from "react";
import StandardSectionHeader from "./StandardSectionHeader";

export default function ThreeColumnFeatureSection({
  title,
  header,
  description,
  features,
}) {
  return (
    <section className="relative bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-4 md:py-8">
        <StandardSectionHeader
          title={title}
          header={header}
          description={description}
        />
        <div className="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">
          {features.map((feature, index) => (
            <div key={`${Math.random()}-${index}`}>
              <dl>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <h4 className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </h4>
                </dt>
              </dl>
              <dl>
                <dd className="mt-2 text-base text-gray-500">
                  {feature.description}
                </dd>
              </dl>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
